import ErrorBoundary from '@/components/Common/ErrorBoundary';
import Head from 'next/head';
import NProgress from 'nprogress';
import React, { useEffect, useMemo, useState } from 'react';
import Script from 'next/script';
import TagManager from 'react-gtm-module';
import storage from '@/shared/utils/storage';
import { IntercomProvider } from 'react-use-intercom';
import { CAMPAIGN_DATA, WEBSITE_URL, STATIC_FILE_URL } from '@/shared/constants';
import { ConfigProvider as AntdConfigProvider, App as AntdApp } from 'antd';
import { ConfigProvider, CreateAccountProvider } from '@/lib/context';
import { NextPage } from 'next';
import { setAnonymousUser } from '@/shared/utils/utils';
import { useRouter } from 'next/router';
import type { AppProps } from 'next/app';
import { useGraphQLClient } from '@/lib/hooks/useCreateGraphQLClient';
import { ApolloProvider } from '@apollo/client';
import { BuilderContent } from '@builder.io/react';
import { HistoryListProvider } from '@/lib/context/HistoryListProvider';
import { init as initApm } from '@elastic/apm-rum';
import '@/styles/fonts.less';
import '@/styles/globals.less';
import 'nprogress/nprogress.css';
import Spinner from '@/components/Common/Spinner';

initApm({
  serviceName: 'pietra-landing-pages',
  serverUrl: process.env.NEXT_PUBLIC_ELASTIC_APM_SERVER_URL,
  environment: process.env.NEXT_PUBLIC_ENV,
  distributedTracingOrigins: [process.env.NEXT_PUBLIC_API_HOST, process.env.NEXT_PUBLIC_GRAPHQL_API_HOST],
});

const INTERCOM_APP_ID = 'hjtdc232';

NProgress.configure({ showSpinner: false });

export type NextPageWithLayout<P = {}> = NextPage<P> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
  content?: BuilderContent;
};

type AppPropsWithLayout<P = {}> = AppProps<P> & {
  Component: NextPageWithLayout<P>;
};

export default function App({
  Component,
  pageProps,
}: AppPropsWithLayout<{ userAgent?: string; head?: any; props?: any }>) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);

  const router = useRouter();
  const apolloClient = useGraphQLClient({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setAnonymousUser();

    const handleStart = () => {
      NProgress.start();
    };

    const handleStop = () => {
      NProgress.done();
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleStop);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleStop);
    };
  }, [router]);

  useEffect(() => {
    const query = router.query;
    if (
      query &&
      (query.hasOwnProperty('utm_campaign') ||
        query.hasOwnProperty('utm_medium') ||
        query.hasOwnProperty('utm_source') ||
        query.hasOwnProperty('utm_content'))
    ) {
      storage.set(CAMPAIGN_DATA, {
        campaign: query['utm_campaign'] || '',
        campaignSource: query['utm_source'] || '',
        campaignMedium: query['utm_medium'] || '',
        campaignContent: query['utm_content'] || '',
      });
    }
  }, [router.query]);

  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-576D7WT' });
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, []);

  const asPath = router.asPath ?? router.route;

  const tagline = 'Pietra | Software & services to run your brand profitably';
  const description = 'Get the best prices on all the tools you need to run a storefront.';

  return (
    <>
      {useMemo(() => {
        return (
          <Head>
            <title key="title">{tagline}</title>
            <meta name="google-site-verification" content="l06Td3J_TNaxRTV30x0y6il60iJxZSxj-4IWylsnJrQ" />
            <meta key="description" name="description" content={description} />
            <meta key="og:url" property="og:url" content={WEBSITE_URL} />
            <meta property="og:type" content="website" />
            <meta key="og:title" property="og:title" content={tagline} />
            <meta key="og:description" property="og:description" content={description} />

            <meta
              key="og:image"
              property="og:image"
              content={`${STATIC_FILE_URL}/692088a2f83f13c9d6f14c04111a5207.png`}
            />
            <meta name="theme-color" content="#ffffff" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta key="twitter:domain" property="twitter:domain" content="pietrastudio.com" />
            <meta key="twitter:url" property="twitter:url" content={WEBSITE_URL} />
            <meta key="twitter:title" name="twitter:title" content={tagline} />
            <meta key="twitter:description" name="twitter:description" content={description} />
            <meta
              key="twitter:image"
              name="twitter:image"
              content={`${STATIC_FILE_URL}/315508b15a0fa1fcf9e2145f113c5c92.png`}
            />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=yes" />
            <meta name="mobile-web-app-capable" content="yes" />
            <meta name="apple-mobile-web-app-title" content="pietra-website" />
            <link rel="mask-icon" href={`${STATIC_FILE_URL}/321a869e20aa62283f1ba7c562419651.svg`} color="#FF5C3C" />
            <link rel="icon" href="/favicon.ico" />
            <link
              rel="apple-touch-icon"
              sizes="256x256"
              href={`${STATIC_FILE_URL}/ea654fc8f679ea800871e264a602e86a.png`}
            />
            <link rel="canonical" href={asPath === '/' ? WEBSITE_URL : WEBSITE_URL + asPath} />
          </Head>
        );
      }, [asPath])}

      <AntdConfigProvider
        prefixCls="pietra"
        theme={{
          token: {
            colorPrimary: '#f9e070',
            colorError: '#ff5c3c',
            colorLink: '#141414',
            colorLinkHover: '#141414',
            colorLinkActive: '#141414',
            borderRadius: 6,
            wireframe: false,
            fontFamily: 'Labil-Regular',
            colorTextPlaceholder: 'rgba(20, 20, 20, 0.6)',
          },
          components: {
            Dropdown: {},
            Message: {
              colorBgElevated: '#141414',
              borderRadius: 0,
              borderRadiusLG: 0,
              controlHeightLG: 55,
            },
            Radio: {
              colorPrimary: '#141414',
              colorPrimaryHover: '#141414',
            },
            Checkbox: {
              colorPrimary: '#141414',
              colorPrimaryHover: '#141414',
            },
            Select: {
              colorPrimaryHover: '#141414',
              colorPrimary: '#141414',
            },
            Input: {
              colorPrimary: '#141414',
              colorPrimaryHover: '#141414',
            },
          },
        }}
      >
        <ErrorBoundary>
          <ApolloProvider client={apolloClient}>
            <AntdApp>
              <ConfigProvider>
                {/* IMPORTANT: Must use 0.0.0.0:3000 in order to test Intercom */}
                <IntercomProvider appId={INTERCOM_APP_ID} initializeDelay={1000}>
                  <HistoryListProvider>
                    <CreateAccountProvider>{getLayout(<Component {...pageProps} />)}</CreateAccountProvider>
                  </HistoryListProvider>
                  {/* <!-- Google tag (gtag.js) --> */}
                  <Script
                    id="google-tag-manager"
                    strategy="lazyOnload"
                    src={'https://www.googletagmanager.com/gtag/js?id=G-DLRX0HHF06'}
                  />
                  <Script
                    id="google-dataLayer"
                    strategy="lazyOnload"
                    dangerouslySetInnerHTML={{
                      __html: `
                      window.dataLayer = window.dataLayer || [];
                      function gtag(){dataLayer.push(arguments);}
                      gtag('js', new Date());
            
                      gtag('config', 'G-DLRX0HHF06');
                    `,
                    }}
                  />
                  {/* <!-- Google Tag Manager --> */}
                  <Script id="google-tag-manager" strategy="afterInteractive" src="/scripts/google-tag-manager.js" />
                  <Script id="email-pietra" strategy="lazyOnload" src="/scripts/email-pietra.js"></Script>
                  {/* <!-- End Google Tag Manager --> */}
                </IntercomProvider>
              </ConfigProvider>
            </AntdApp>
          </ApolloProvider>
          {loading && <Spinner fullPage />}
        </ErrorBoundary>
      </AntdConfigProvider>
    </>
  );
}
